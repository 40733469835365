import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_Home_Assistant_Tutorials/PrimaryBox.jsx';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Home Assistant 2022.8.6 and the INSTAR WQHD Live Videostream",
  "path": "/Frequently_Asked_Question/Home_Assistant_INSTAR_WQHD_Camera_Live_Video/",
  "dateChanged": "2022-08-25",
  "author": "Mike Polinowski",
  "excerpt": "I want to use my IN-9408 2k+ with the new Version of Home Assistant. How can I add my cameras live stream to the Home Assistant dashboard?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Home Assistant 2022.8.6 and the INSTAR WQHD Live Videostream' dateChanged='2022-08-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to use my IN-9408 2k+ with the new Version of Home Assistant. How can I add my cameras live stream to the Home Assistant dashboard?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Home_Assistant_INSTAR_WQHD_Camera_Live_Video/' locationFR='/fr/Frequently_Asked_Question/Home_Assistant_INSTAR_WQHD_Camera_Live_Video/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    
    <h1 {...{
      "id": "home-assistant-202286-and-the-instar-wqhd-live-videostream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#home-assistant-202286-and-the-instar-wqhd-live-videostream",
        "aria-label": "home assistant 202286 and the instar wqhd live videostream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Home Assistant 2022.8.6 and the INSTAR WQHD Live Videostream`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#rtsp-ip-camera"
        }}>{`RTSP IP Camera`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#mjpeg-ip-camera"
        }}>{`MJPEG IP Camera`}</a></li>
    </ul>
    <h2 {...{
      "id": "rtsp-ip-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#rtsp-ip-camera",
        "aria-label": "rtsp ip camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`RTSP IP Camera`}</h2>
    <p>{`We can use the `}<a parentName="p" {...{
        "href": "https://www.home-assistant.io/integrations/generic"
      }}>{`Generic Camera`}</a>{` addon to add our cameras live video. Start by install from the `}<em parentName="p">{`Settings`}</em>{` / `}<em parentName="p">{`Devices and Services`}</em>{` / `}<em parentName="p">{`Integrations`}</em>{` screen:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8c7d4e303d765b47e4648fb7ae597415/8ea22/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.21739130434782%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABA0lEQVQY002PQVLDMAxFcwCmRZZkW5acSZuENklbmJaBDQsWXID7X4ZxPO3wFtr9/74aZhaRXGiZmYhcAZwXl7JDTCJMFGP0nkMIMUbnHCISUSMiKSVJwkyImCqqnJSicigZZvbeE1HN1Ou9b0IIqhpCKDqAnPPpdJ7neVnm18tlHAZVNbO2bc0sxoiI1eyca6qp9gFAa/Z5fbvebmt+OU7TYWVZlq7r6l+4AgCN936tDA7RPW9lf9i/f70M/TCOu3/0fa+qAIB3irmuKrMRcbvh8RQ+foau7dfBD8xMRADA3SlmVU0pMTMi4eaJ51v8/j32u+M0dV1XY2aWc04p1W8f5j/qnSr8lYskywAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8c7d4e303d765b47e4648fb7ae597415/e4706/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_01.avif 230w", "/en/static/8c7d4e303d765b47e4648fb7ae597415/d1af7/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_01.avif 460w", "/en/static/8c7d4e303d765b47e4648fb7ae597415/7f308/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_01.avif 920w", "/en/static/8c7d4e303d765b47e4648fb7ae597415/fab00/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_01.avif 1023w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8c7d4e303d765b47e4648fb7ae597415/a0b58/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_01.webp 230w", "/en/static/8c7d4e303d765b47e4648fb7ae597415/bc10c/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_01.webp 460w", "/en/static/8c7d4e303d765b47e4648fb7ae597415/966d8/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_01.webp 920w", "/en/static/8c7d4e303d765b47e4648fb7ae597415/da35d/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_01.webp 1023w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8c7d4e303d765b47e4648fb7ae597415/81c8e/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_01.png 230w", "/en/static/8c7d4e303d765b47e4648fb7ae597415/08a84/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_01.png 460w", "/en/static/8c7d4e303d765b47e4648fb7ae597415/c0255/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_01.png 920w", "/en/static/8c7d4e303d765b47e4648fb7ae597415/8ea22/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_01.png 1023w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8c7d4e303d765b47e4648fb7ae597415/c0255/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_01.png",
              "alt": "Home Assistant 2022.8.6 and the INSTAR WQHD Live Videostream",
              "title": "Home Assistant 2022.8.6 and the INSTAR WQHD Live Videostream",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we need the `}<a parentName="p" {...{
        "href": "/en/1440p_Series_CGI_List/#livestream"
      }}>{`JPG and RTSP Path`}</a>{` of our camera, e.g. on IP address `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.115`}</code>{`.`}</p>
    <p><em parentName="p">{`Snapshots for video channels 11, 12 and 13`}</em>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.115/snap.cgi?chn`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
http://192.168.2.115/snap.cgi?chn`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
http://192.168.2.115/snap.cgi?chn`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`13`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <p><em parentName="p">{`RTSP Stream /livestream for video channels 11, 12 and 13`}</em>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`rtsp://admin:instar@192.168.2.115:554/livestream/11
rtsp://admin:instar@192.168.2.115:554/livestream/12
rtsp://admin:instar@192.168.2.115:554/livestream/13`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/489d1802f30569140feb71b3a6af8ce1/1ac29/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABw0lEQVQoz42SyZabMBBFvTekrRmphAY0AAaD7c7/f1uOIHY4nV70XevWq/NKJ0qplFJvMFpQSsGGUkoIIaVUG3yjaRqMMdk4Sam01gDlHWOs4Wzo++fzeb/f53k2xgzDkHN2zgkhCCGMsd0UQpw45wDAGCvzMGbGdSmPwzCOY9/3wzD0fW+ddc6BBs45IQRvlGSllJSyjKIUXy44X9O8/P587uF7fs45xmjaVghBKd2TEUInIYTW+u9IQtDHR+f8ut6XZbndbtM05ULqYqSiQQiRAyX5n4wxIjiluBbxNs/z9XqNMYauwJsi4xcleS+WsbIMRog3wo5zmtaconNOSrnXbq0FgHfPe9LpuMY+UBvrYwIoB9tPCADGGM75US5rf5EJpdaYnFLO2RijXxhjEEJVVdUHvspN01hrQwjWWn3AGHO5XM7nc3Xgq4wQ8t6P47Uc9rX2T+WPXzX4FKfFmhZ+kvz+MRjjuq4BoAuhbdv3JweAtm2/kRkhshEgJaO0VFLX3vt5mkII8YD3/hsZu0S7gbtItEFCVlUVQliWJW2Fv+m67jtZtVhqJBRpFOaiqqoY4+PxyDn3GymldV1zztV//AFXD138jOQ/8wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/489d1802f30569140feb71b3a6af8ce1/e4706/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_02.avif 230w", "/en/static/489d1802f30569140feb71b3a6af8ce1/d1af7/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_02.avif 460w", "/en/static/489d1802f30569140feb71b3a6af8ce1/7f308/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_02.avif 920w", "/en/static/489d1802f30569140feb71b3a6af8ce1/5dd4b/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_02.avif 1022w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/489d1802f30569140feb71b3a6af8ce1/a0b58/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_02.webp 230w", "/en/static/489d1802f30569140feb71b3a6af8ce1/bc10c/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_02.webp 460w", "/en/static/489d1802f30569140feb71b3a6af8ce1/966d8/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_02.webp 920w", "/en/static/489d1802f30569140feb71b3a6af8ce1/6c19f/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_02.webp 1022w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/489d1802f30569140feb71b3a6af8ce1/81c8e/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_02.png 230w", "/en/static/489d1802f30569140feb71b3a6af8ce1/08a84/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_02.png 460w", "/en/static/489d1802f30569140feb71b3a6af8ce1/c0255/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_02.png 920w", "/en/static/489d1802f30569140feb71b3a6af8ce1/1ac29/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_02.png 1022w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/489d1802f30569140feb71b3a6af8ce1/c0255/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_02.png",
              "alt": "Home Assistant 2022.8.6 and the INSTAR WQHD Live Videostream",
              "title": "Home Assistant 2022.8.6 and the INSTAR WQHD Live Videostream",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back on the dashboard we can now add our camera as an `}<strong parentName="p">{`Entity`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/938d84d312ae12ea5d61f5b0ebf52801/b12f7/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACG0lEQVQ4y3WT2W6cMBiFuajUZkDBZgbwilewWc1k0qSVqki96F1foC/Q93+EiiXRpO18OkK/j3TA9hERUP7ElXeu7/u2bccQwnx2vuVCVFL+K6FUmM/T15fw8iPKCceMywUlhJBSGK1FVVFK/i9CpBSnvADHU0QwpgQ3/dg9PA3j5LvO1k3dNLVzq/w2NPtyGYy1AID4cIiKosSE+H6YLk/dfKmHYPvJ9qPtVvnOtMOublicfjJtByFMkiSilFJCTHjsxzB0nfe+vsKEz75d3LZtvXObaa2BEMZxHBFCCMYyPPthappaac2uwO0sjTXaaGOFkIxRxlhVVcu24zjKsqwoCmeNc44xRim9DnOC6BWbKYR4F972o5T6K/xu8fpqzjmEWZwke7jruvP5HEJQainsFlLKagXEd/HHD9HxeMzzXGv9sHK5XJa7uUHf93VdaymBezjIdg9LKadp2mLOuaZptuc2bPj1zpejoRL+/H34/msPF0WBMUYIlWXJOddayVeEENtpy7JECOUr6f19fPcpyvMcIQQhTFMAAEjT1GgbwjxO0zzP4zgaY7YweAVmGQBpHCfRWjM+ZlkGQQYBgEAyY1StjbbWSikxxnwFLgAIQbaE16qOEKKyxJSjSuJKkr1R8sb22d3ignBBeQW38PD4zBmTtqHWE2UJZeQ2WKhFvErTdOl5+vINoeXHKk7HDIAkSeLbJIfDrpU/7xR2yFAJ6jUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/938d84d312ae12ea5d61f5b0ebf52801/e4706/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_03.avif 230w", "/en/static/938d84d312ae12ea5d61f5b0ebf52801/d1af7/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_03.avif 460w", "/en/static/938d84d312ae12ea5d61f5b0ebf52801/7f308/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_03.avif 920w", "/en/static/938d84d312ae12ea5d61f5b0ebf52801/2c8af/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_03.avif 1020w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/938d84d312ae12ea5d61f5b0ebf52801/a0b58/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_03.webp 230w", "/en/static/938d84d312ae12ea5d61f5b0ebf52801/bc10c/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_03.webp 460w", "/en/static/938d84d312ae12ea5d61f5b0ebf52801/966d8/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_03.webp 920w", "/en/static/938d84d312ae12ea5d61f5b0ebf52801/52c2b/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_03.webp 1020w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/938d84d312ae12ea5d61f5b0ebf52801/81c8e/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_03.png 230w", "/en/static/938d84d312ae12ea5d61f5b0ebf52801/08a84/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_03.png 460w", "/en/static/938d84d312ae12ea5d61f5b0ebf52801/c0255/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_03.png 920w", "/en/static/938d84d312ae12ea5d61f5b0ebf52801/b12f7/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_03.png 1020w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/938d84d312ae12ea5d61f5b0ebf52801/c0255/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_03.png",
              "alt": "Home Assistant 2022.8.6 and the INSTAR WQHD Live Videostream",
              "title": "Home Assistant 2022.8.6 and the INSTAR WQHD Live Videostream",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once saved you can open your cameras by clicking the Live Video tile:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/0091a1092b8bc5c1e675166642058c8c/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_04.gif",
        "alt": "Home Assistant 2022.8.6 and the INSTAR WQHD Live Videostream"
      }}></img></p>
    <h2 {...{
      "id": "mjpeg-ip-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mjpeg-ip-camera",
        "aria-label": "mjpeg ip camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MJPEG IP Camera`}</h2>
    <p>{`Using the `}<a parentName="p" {...{
        "href": "https://www.home-assistant.io/integrations/mjpeg"
      }}>{`MJPEG Camera`}</a>{` integration works the same way:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4bfa1d279b94addfc4262d90f6db3719/35a31/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABB0lEQVQY002RyW7CMBRF8wUt8bPfaJsQQwiUIUWquijbLqr+//9UiVXE0d0e3Tc0iKQqeQERvfew4Ehbzc4HEfEhEDOGQEQiDOAAwHvfqJqZqWq1zCylFGNkjUEMWYgIaSaE4NysOee894jYIJGpMhEAtG1rMV6n6XI6XS/n2zTthyHFmFPq1uucMmKoEJGqNrr0hhCqHM2m99v4cS/H83B42/1nPF26spOYMa6RsPY3xGxmIuIA3OsLllG+fsp2ux2PuS9p09fkvmhMq1Xbgn8M38R5w8TMVQ79nj+/+5z60s+rmz1nPt6iIaKINCwzdexZLge+/w5lM+zHruvMLD5BxI93AMAf55ksDd7Fwo4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4bfa1d279b94addfc4262d90f6db3719/e4706/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_05.avif 230w", "/en/static/4bfa1d279b94addfc4262d90f6db3719/d1af7/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_05.avif 460w", "/en/static/4bfa1d279b94addfc4262d90f6db3719/7f308/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_05.avif 920w", "/en/static/4bfa1d279b94addfc4262d90f6db3719/25925/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_05.avif 1028w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4bfa1d279b94addfc4262d90f6db3719/a0b58/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_05.webp 230w", "/en/static/4bfa1d279b94addfc4262d90f6db3719/bc10c/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_05.webp 460w", "/en/static/4bfa1d279b94addfc4262d90f6db3719/966d8/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_05.webp 920w", "/en/static/4bfa1d279b94addfc4262d90f6db3719/5ea8e/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_05.webp 1028w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4bfa1d279b94addfc4262d90f6db3719/81c8e/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_05.png 230w", "/en/static/4bfa1d279b94addfc4262d90f6db3719/08a84/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_05.png 460w", "/en/static/4bfa1d279b94addfc4262d90f6db3719/c0255/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_05.png 920w", "/en/static/4bfa1d279b94addfc4262d90f6db3719/35a31/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_05.png 1028w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4bfa1d279b94addfc4262d90f6db3719/c0255/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_05.png",
              "alt": "Home Assistant 2022.8.6 and the INSTAR WQHD Live Videostream",
              "title": "Home Assistant 2022.8.6 and the INSTAR WQHD Live Videostream",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Only now we need to use the `}<a parentName="p" {...{
        "href": "/en/1440p_Series_CGI_List/#livestream"
      }}>{`MJPEG Path`}</a>{` of our camera, e.g. on IP address `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.115`}</code>{`.`}</p>
    <p><em parentName="p">{`Videostreams for video channels 11, 12 and 13`}</em>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.115/livestream/11?action`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`play`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`media`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`mjpeg`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
http://192.168.2.115/livestream/12?action`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`play`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`media`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`mjpeg`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
http://192.168.2.115/livestream/13?action`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`play`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`media`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`mjpeg`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9d6ad311cde465fc839bdb365a233efa/6bfd0/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABIElEQVQoz23Q63KDIBAFYB/AVFkuUrW74AWNESTm/d+tQ1KdptOPf8ycOQey+qmqKuCcA5Sysu4avL9er+u6EhEiTtPUGKtaasgotEprwZNMa13Xtf7UACleAEMi7/08z8PBOWeIAKAsS8bgRQiREVHTNEopDunID9l9dX7zy7K4X2zXaa2rqhJCMMaEEG3bZudVWs1BMGXN8HjsIYRt22KM27bt++6ce22UUj4n89T82sAPBfuZvdxu82Gapq7rGGNlWcIhvZn/lj5MY+/u91QYQogxruuaZluLiER0NgPAe5hzBkDGeO+HYRhPzlljZIO6Rd4gl4rz54f9CRdFQYghBPeu73uANJwd/mnO8xwRY4xnbBzHZVnmeb5cLvm7b7Y3OiN6xCe2AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9d6ad311cde465fc839bdb365a233efa/e4706/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_06.avif 230w", "/en/static/9d6ad311cde465fc839bdb365a233efa/d1af7/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_06.avif 460w", "/en/static/9d6ad311cde465fc839bdb365a233efa/7f308/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_06.avif 920w", "/en/static/9d6ad311cde465fc839bdb365a233efa/ecfe0/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_06.avif 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9d6ad311cde465fc839bdb365a233efa/a0b58/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_06.webp 230w", "/en/static/9d6ad311cde465fc839bdb365a233efa/bc10c/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_06.webp 460w", "/en/static/9d6ad311cde465fc839bdb365a233efa/966d8/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_06.webp 920w", "/en/static/9d6ad311cde465fc839bdb365a233efa/7ed23/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_06.webp 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9d6ad311cde465fc839bdb365a233efa/81c8e/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_06.png 230w", "/en/static/9d6ad311cde465fc839bdb365a233efa/08a84/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_06.png 460w", "/en/static/9d6ad311cde465fc839bdb365a233efa/c0255/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_06.png 920w", "/en/static/9d6ad311cde465fc839bdb365a233efa/6bfd0/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_06.png 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9d6ad311cde465fc839bdb365a233efa/c0255/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_06.png",
              "alt": "Home Assistant 2022.8.6 and the INSTAR WQHD Live Videostream",
              "title": "Home Assistant 2022.8.6 and the INSTAR WQHD Live Videostream",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back on the dashboard we can now add our camera as an `}<strong parentName="p">{`Entity`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2a8795fee13afe2a7ddda0b33dcd2793/5b587/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAByUlEQVQoz3WS2a6bMBCGuelysjTE4HgBvGBjbPCShChSpV72pg/Rl+mTt0AUnSM1n74LNDM/kkeT5ToA1hpjvPfjOHrvU0rW2voFlNJhcH66xx8/M0gophXnXErJF5RSQojmBXVdSykRxt8Oh6yilBDsnJumW0rRe98t9K8xxkAIN29vGUIIE2JNl2IIITjnPg52s0vgWbPWEkLyPM/qpqEYt+E6pKsfBmtt90QrFW9mjM72zrlnyxhDCEEIZVVdU4x4uJmQetO1Sq0Pm6ko6SPTVrVSay2EWMuMMYwxhDADAJRl2anW2Z4xRil9v9umonVFq4XHD6tKCIEQAgBkRVFACLXWxhil1Nz+6HuqapaxBkK43e4eYWttSulyPkspOGevFHOTcS5gAbafPz3CjHN7vYfpbsLZjPG/dmPo40XbQUlxau22vz3CnPPrNKXzxcfkYxx9GBbHMOtDnI0pprMdxvpUwu+/Nr//PMIIoWUXM5xzrWb0ompbsVze+mJCyOl0Asfj5uuXrCxLjDEA4LCw3++bpolxvpj12vu+X5ec5/k6c1zY7XbZ2iiKYi0dDgfGmDFGa922bbXut2mklAUA6wxYPv6F/wKaJmjj39Gz+QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2a8795fee13afe2a7ddda0b33dcd2793/e4706/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_07.avif 230w", "/en/static/2a8795fee13afe2a7ddda0b33dcd2793/d1af7/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_07.avif 460w", "/en/static/2a8795fee13afe2a7ddda0b33dcd2793/7f308/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_07.avif 920w", "/en/static/2a8795fee13afe2a7ddda0b33dcd2793/87d2a/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_07.avif 1010w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2a8795fee13afe2a7ddda0b33dcd2793/a0b58/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_07.webp 230w", "/en/static/2a8795fee13afe2a7ddda0b33dcd2793/bc10c/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_07.webp 460w", "/en/static/2a8795fee13afe2a7ddda0b33dcd2793/966d8/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_07.webp 920w", "/en/static/2a8795fee13afe2a7ddda0b33dcd2793/93852/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_07.webp 1010w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2a8795fee13afe2a7ddda0b33dcd2793/81c8e/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_07.png 230w", "/en/static/2a8795fee13afe2a7ddda0b33dcd2793/08a84/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_07.png 460w", "/en/static/2a8795fee13afe2a7ddda0b33dcd2793/c0255/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_07.png 920w", "/en/static/2a8795fee13afe2a7ddda0b33dcd2793/5b587/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_07.png 1010w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2a8795fee13afe2a7ddda0b33dcd2793/c0255/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_07.png",
              "alt": "Home Assistant 2022.8.6 and the INSTAR WQHD Live Videostream",
              "title": "Home Assistant 2022.8.6 and the INSTAR WQHD Live Videostream",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once saved you can open your cameras by clicking the Live Video tile:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/59c7defe14143a01568358b6c1d03bce/159fb/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABYElEQVQoz0WQy27cMAxFtW8SP+HRkyL1sCR7NGO3SBcNZpX+/zcVY6ftwV0Ql7wgQZbmWK/XW61ENGGU+aaXW4+xBf8l41+1a4z/53Q28LAMODP+/il/fqr9Q26/Ri77YRBS9cPYHeqHUQlxdaj4pemH7q9/ivn1jnkV6DSFYRzfnrw2TdO1bde2fdtO0/S7pqT5t5eXtvmiPboszRGM1kopKY3R1lpEMsbwAyklgL1IBYgWLFokQkSSQnLOWc45hKAOiNx9+77v+7ou+sA5V2v1FH7s79u+1Vq3+z3nbJ8ASymdYa2UBotxSXOMMerjGiKqt6o4OIhIGEJAxGm6SCmIiJVSiOgMG4surSXnlNL/cK3GmFzyuq455+uBtSCEYABgjDlHjUWfayklpXw659nzPD8ej1LKsizOOXIEAN579tx58qyMdTEE772XUgohACClpJQKIXDOhRCXg/OXfwDYKkFxuG8tqwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/59c7defe14143a01568358b6c1d03bce/e4706/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_08.avif 230w", "/en/static/59c7defe14143a01568358b6c1d03bce/d1af7/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_08.avif 460w", "/en/static/59c7defe14143a01568358b6c1d03bce/7f308/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_08.avif 920w", "/en/static/59c7defe14143a01568358b6c1d03bce/5309c/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_08.avif 1019w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/59c7defe14143a01568358b6c1d03bce/a0b58/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_08.webp 230w", "/en/static/59c7defe14143a01568358b6c1d03bce/bc10c/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_08.webp 460w", "/en/static/59c7defe14143a01568358b6c1d03bce/966d8/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_08.webp 920w", "/en/static/59c7defe14143a01568358b6c1d03bce/6b83e/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_08.webp 1019w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/59c7defe14143a01568358b6c1d03bce/81c8e/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_08.png 230w", "/en/static/59c7defe14143a01568358b6c1d03bce/08a84/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_08.png 460w", "/en/static/59c7defe14143a01568358b6c1d03bce/c0255/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_08.png 920w", "/en/static/59c7defe14143a01568358b6c1d03bce/159fb/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_08.png 1019w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/59c7defe14143a01568358b6c1d03bce/c0255/Home_Assistant_INSTAR_WQHD_Camera_Live_Video_08.png",
              "alt": "Home Assistant 2022.8.6 and the INSTAR WQHD Live Videostream",
              "title": "Home Assistant 2022.8.6 and the INSTAR WQHD Live Videostream",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      